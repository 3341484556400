import React, { useEffect } from "react";
import { DATE_FORMAT_PRIMARY, formatDate } from "../../utils/date";
import { formatPrice } from "../../utils/money";
import defaultImage from "../../../static/img/default-bc-product.png";
// import useToggle from "../../hooks/useToggle";
import Image from "../common/Image";
import { checkCustomerGroup } from "../products/Price";
import { useSelector } from "react-redux";
import { Link, navigate } from "gatsby";
import useGetAccountURL from "../../hooks/bc-login/useGetAccountURL";
import { Pagination } from "../../utils/pagination";
import { isOrderReturnable } from "../../utils/account";

function OrderListing({ setPage, orders, page, isNextPage }) {
  // const { currentPage, totalPages } = pagination;

  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [page]);

  const handleNext = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setPage((prev) => prev + 1);
  };

  const handlePrevious = () => {
    setPage((prev) => prev - 1);
  };

  const { currentLoginUser } = useSelector((state) => state.customer);
  const currentUserData = checkCustomerGroup(currentLoginUser);
  const { accountURL } = useGetAccountURL();

  return (
    <>
      <div className="">
        <div className="">
          {orders && orders.length ? (
            <ul className="order-list">
              {orders.map((item) => (
                <li className="account-listItem" key={item.id}>
                  <div className="row flex vertical-top">
                    <div className="col account-product-figure">
                      <Image
                        src={item.image || defaultImage}
                        alt="ProductThumb"
                      />
                    </div>
                    <div className="col account-product-body">
                      <div className="account-right-tag account-orderStatus">
                        <h6 className="account-orderStatus-label">
                          {item.order_status}
                        </h6>
                        {isOrderReturnable(item?.order_status) ? (
                          <p
                            className="order-return-link link-style"
                            onClick={() => {
                              navigate(`/returns?order_id=${item.order_id}`, {
                                state: {
                                  orderID: item.order_id,
                                },
                              });
                            }}
                          >
                            Return Items?
                          </p>
                        ) : null}
                      </div>

                      <h5 className="account-product-title">
                        <Link
                          to={`/order-detail/?id=${item.order_id}`}
                        >{`Order #${item.order_id}`}</Link>
                      </h5>
                      <p className="account-product-description">
                        {item.total_items}{" "}
                        {item.total_items > 1 ? "products" : "product"} totaling{" "}
                        <br />
                        <div className="bc-product__pricing initialized product-price-wrap">
                          <div className="price-wrapper">
                            <div className="customer-badge-id-overlay">
                              <div>{item?.customer_id}</div>
                            </div>
                            <div className="product-price-wrapper">
                              <p className="bc-product__pricing--api bc-product__pricing--visible product-price-item">
                                <span className="original-price-node bc-product__original-price bc-show-current-price current-price">
                                  <span>{` ${formatPrice(
                                    item.total_inc_tax.toString()
                                  )}`}</span>
                                </span>
                              </p>
                            </div>
                          </div>
                          <div
                            className={`customer-badge-section flex vertical-middle ${currentUserData?.className}`}
                          >
                            <div className="customer-badge-icon">
                              <Image src={currentUserData?.icon} />
                            </div>
                            <div className="customer-badge-name">
                              {currentUserData?.type}
                            </div>
                            <div className="customer-badge-id">
                              {currentLoginUser?.id}
                            </div>
                          </div>
                        </div>
                      </p>

                      <div className="account-product-details flex flex-wrap">
                        <div className="account-product-detail">
                          <p className="account-product-detail-heading">
                            Order Placed
                          </p>
                          <strong>
                            {formatDate(item.order_date, DATE_FORMAT_PRIMARY)}
                          </strong>
                        </div>
                        <div className="account-product-detail">
                          <p className="account-product-detail-heading">
                            Last Update
                          </p>
                          <strong>
                            {formatDate(item.update_date, DATE_FORMAT_PRIMARY)}
                          </strong>
                        </div>
                      </div>
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          ) : (
            <h6 className="text-center">Orders not found</h6>
          )}

          {!isNextPage && page === 1 ? null : (
            <div className="account-pagination">
              <button
                className="button pagination-button button-small"
                onClick={handlePrevious}
                disabled={page === 1}
              >
                Previous
              </button>
              <span className="pagination-page-count">{page}</span>
              <button
                className="button pagination-button button-small"
                onClick={handleNext}
                disabled={!isNextPage}
              >
                Next
              </button>
              {/* <Pagination
              pagination={pagination}
              onPageChange={onPageChange}
              location={location}
            /> */}
            </div>
          )}
        </div>

        {/* login to bc store */}
        <iframe
          src={accountURL}
          title="bc-login"
          id="bc-login"
          height="0px"
          onLoad={() => console.log("BC iframe loaded")}
          style={{ display: "none" }}
          width="0px"
        />
      </div>
    </>
  );
}

export default OrderListing;
