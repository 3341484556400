import Modal from "react-modal";
import PropTypes from "prop-types";
import React from "react";
import IconClose from "../../../assets/icon_js/IconClose";
import LoginForm from "../../../sections/login/LoginForm";

Modal.setAppElement("body");

const DialogHead = ({ children }) => {
  return <div className="modal-title">{children}</div>;
};

function LoginModal({ isOpen, setIsOpen, title, showClose = true }) {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <Modal isOpen={isOpen} onRequestClose={closeModal}>
      <div className="modal-outer">
        <div className="modal-inner">
          <div className="modal-body-outer">
            {showClose && (
              <button
                type="button"
                className="modal-close-button"
                data-dismiss="modal"
                aria-label="Close"
                onClick={closeModal}
              >
                <IconClose />
              </button>
            )}

            {title !== "" && (
              <div className="modal-header">
                <DialogHead>{title}</DialogHead>
              </div>
            )}

            <div className="modal-body">
              <div className="login-modal-wrapper">
                <LoginForm setCloseModal={closeModal} isOpen={isOpen} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
}

LoginModal.propTypes = {
  chidlren: PropTypes.node,
  title: PropTypes.string,
  isOpen: PropTypes.bool,
  setIsOpen: PropTypes.func,
};

export default LoginModal;
