import { navigate } from "gatsby";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import API from "../../services/Api";
import { myAccountURL } from "../../ApiEndpoints";

function useGetAccountURL() {
  const [url, setUrl] = useState(null);

  const { user } = useSelector((state) => state.auth);

  useEffect(async () => {
    if (!user) {
      navigate("/login");
    } else {
      if (!url) {
        const account = await getMyAccountUrl(myAccountURL);
        const accountURL = account?.["url"] || null;
        if (accountURL) {
          setUrl(accountURL);
        }
      }
    }
  }, [user]);

  useEffect(() => {
    const previousData = sessionStorage.getItem("visitorData");
    const previousVisitorData = previousData ? JSON.parse(previousData) : null;

    if (user) {
      if (previousVisitorData && !previousVisitorData["isFirstT"]) {
        const visitorData = {
          customerId: user["customer_id"],
          isFirstT: false,
        };
        sessionStorage.setItem("visitorData", JSON.stringify(visitorData));
      } else {
        const visitorData = {
          customerId: user["customer_id"],
          isFirstT: false,
        };
        sessionStorage.setItem("visitorData", JSON.stringify(visitorData));
        // window.location.reload(true);
      }
    }
  }, []);

  return {
    accountURL: url,
  };
}

async function getMyAccountUrl(url) {
  const response = await API.get(url);
  return response?.data;
}

export default useGetAccountURL;
