import React, { useState } from "react";
import { calculatePrices, formatPrice } from "../../utils/money";
import { isUserLoggedIn } from "../../utils/auth";
import { Link } from "gatsby";
import vipbadge from "../../assets/images/vip.png";
import supervipbadge from "../../assets/images/super-vip.png";
import vipprobadge from "../../assets/images/vip-pro.png";
import Image from "../common/Image";
import LoginModal from "../form/Dialog/LoginModal";

export const checkCustomerGroup = (customer) => {
  if (customer?.customer_group_id === 45) {
    return {
      type: `VIP`,
      icon: vipbadge,
      className: "customer-vip",
    };
  }
  if (customer?.customer_group_id === 91) {
    return {
      type: `VIP PRO`,
      icon: vipprobadge,
      className: "customer-vip-pro",
    };
  }
  if (customer?.customer_group_id === 48) {
    return {
      type: `SUPER VIP`,
      icon: supervipbadge,
      className: "customer-super-vip",
    };
  }
};

function Price({ sale_price, price, retail_price, currentUser }) {
  const isUser = isUserLoggedIn();
  const [isOpen, setIsOpen] = useState(false);

  // convert type if not in decimal...
  const { salePrice, defaultPrice, retailPrice } = calculatePrices(
    +sale_price,
    +price,
    +retail_price
  );

  const currentUserData = checkCustomerGroup(currentUser);

  return (
    <div className="bc-product__pricing initialized product-price-wrap">
      {/* vip */}
      <div
        className={`customer-badge-section flex vertical-middle ${currentUserData?.className}`}
      >
        <div className="customer-badge-icon">
          <Image src={currentUserData?.icon} />
        </div>
        <div className="customer-badge-name">{currentUserData?.type}</div>
        <div className="customer-badge-id">{currentUser?.id}</div>
      </div>

      <div>
        <div className="price-wrapper">
          <div className="customer-badge-id-overlay">
            <div>{currentUser?.id}</div>
          </div>

          <div className="product-price-wrapper">
            <p className="bc-product__pricing--api bc-product__pricing--visible product-price-item">
              {isUser ? (
                <>
                  {retailPrice?.value !== null && retailPrice?.value !== 0 ? (
                    <span
                      className={`sale-node bc-product__price bc-product__price--sale bc-show-current-price ${retailPrice.class}`}
                    >
                      <span>{formatPrice(retailPrice.value.toString())}</span>
                    </span>
                  ) : null}
                  {defaultPrice?.value !== null && defaultPrice?.value !== 0 ? (
                    <span
                      className={`original-price-node bc-product__original-price bc-show-current-price ${defaultPrice.class}`}
                    >
                      <span>{formatPrice(defaultPrice.value.toString())}</span>
                    </span>
                  ) : null}
                  {salePrice?.value !== null && salePrice?.value !== 0 ? (
                    <span
                      className={`original-price-node bc-product__original-price bc-show-current-price current-price`}
                    >
                      <span>{formatPrice(salePrice.value.toString())}</span>
                    </span>
                  ) : null}
                </>
              ) : (
                <>
                  <span
                    className="price-login-link signin-link-common"
                    onClick={() => setIsOpen(true)}
                  >
                    <strong>SignIn to see the price</strong>
                  </span>

                  <span
                    className="price-login-link signin-link-product-card"
                    onClick={() => setIsOpen(true)}
                  >
                    <strong>Sign In</strong>
                  </span>
                </>
              )}
            </p>
            {isOpen && (
              <LoginModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                title={"Login"}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Price;
